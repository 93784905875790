import React, { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import StyledButtonOrange from 'components/elements/ButtonOrange'

const StyledClientCase = styled.section``

const ClientCaseBlock = styled.div`
  background-color: ${(props) => props.theme.color.face.contrastLight};
  border-radius: 25px;
`

const ClientCaseBlockContent = styled(ParseContent)`
  & blockquote {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      @media (min-width: 992px) {
        max-height: 250px;
        -webkit-line-clamp: 6;
      }

      @media (max-width: 991px) {
        max-height: 193px;
        -webkit-line-clamp: 8;
      }
    }
  }
`

const ClientCaseImage = styled.div`
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

  @media (min-width: 992px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 991px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 767px) {
    width: 250px;
    height: 250px;
  }

  @media (max-width: 575px) {
    width: 150px;
    height: 150px;
  }
`

const SlideWrapper = styled.div`
  position: relative;
  border-radius: 25px;

  @media (min-width: 1200px) {
    left: 60px;
  }

  @media (min-width: 1200px) {
    height: 750px;
  }

  @media (max-width: 1199px) {
    height: 650px;
  }

  @media (min-width: 992px) {
    overflow: hidden;
  }

  @media (max-width: 991px) {
    height: 550px;
  }

  @media (max-width: 575px) {
    height: 475px;
  }
`

const Slide = styled(motion.div)`
  position: absolute;
  height: 100%;
`

const SlideNavigation = styled.div`
  @media (min-width: 1500px) {
    left: -100px;
    right: -100px;
  }

  @media (max-width: 1499px) {
    left: -75px;
    right: -75px;
  }

  @media (min-width: 1200px) {
    position: absolute;
    top: 50%;
  }

  @media (max-width: 1199px) {
    left: 0;
    right: 0;
  }
`

const ButtonOrange = styled(StyledButtonOrange)`
  @media (max-width: 991px) {
    & button {
      font-size: 0;
    }
  }

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: ${(props) => props.theme.color.text.purple};
    }
  }
`

interface ClientCaseProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ClientCase
}

const ClientCase: React.FC<ClientCaseProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  return (
    <StyledClientCase className="my-1 my-lg-5">
      <div className="container py-5">
        <div className="py-lg-5 position-relative">
          <SlideWrapper>
            <AnimatePresence>
              {fields?.cases?.map((edge, index: number) => {
                const isVisible = index === currentIndex

                if (!isVisible) {
                  return null
                }

                return (
                  <Slide
                    // eslint-disable-next-line react/no-array-index-key
                    key={`slide-${index}`}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                  >
                    <div className="row">
                      <div className="col-9 col-sm-8 col-lg-7">
                        <ClientCaseBlock className="py-md-5 py-4 pr-5 pl-md-5 pl-4 position-relative">
                          <div className="p-lg-3 p-xl-5">
                            <ClientCaseBlockQuote />
                            <ClientCaseBlockContent
                              className="py-xl-5"
                              content={edge?.description}
                            />
                          </div>
                        </ClientCaseBlock>
                      </div>
                      <div className="col-3 col-sm-4 col-lg-5">
                        <div className="pt-5 pb-4">
                          <ParseContent
                            className="d-sm-block d-none"
                            content={fields?.title}
                          />
                        </div>
                        <div className="ml-n5 mb-n4 mb-sm-0">
                          <ClientCaseImage className="ml-n3 ml-sm-n4 ml-5">
                            <Image
                              image={edge?.image}
                              alt={fields?.title}
                              className="w-100 h-100"
                            />
                          </ClientCaseImage>
                        </div>
                      </div>
                    </div>
                  </Slide>
                )
              })}
            </AnimatePresence>
          </SlideWrapper>
          <SlideNavigation className="d-flex align-items-center justify-content-between">
            <ButtonOrange isCustom disabled={currentIndex === 0}>
              <button
                type="button"
                onClick={() => {
                  setCurrentIndex((prevState) => prevState - 1)
                }}
                disabled={currentIndex === 0}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 511.999 511.999"
                >
                  <path
                    d="M172.548,256.005L388.82,39.729c9.089-9.089,9.089-23.824,0-32.912s-23.824-9.089-32.912,0.002
	L123.18,239.551c-4.366,4.363-6.817,10.282-6.817,16.454c0,6.173,2.453,12.093,6.817,16.457l232.727,232.721
	c4.543,4.544,10.499,6.816,16.455,6.816s11.913-2.271,16.457-6.817c9.089-9.089,9.089-23.824,0-32.912L172.548,256.005z"
                  />
                </svg>
                &nbsp; Vorige
              </button>
            </ButtonOrange>
            <ButtonOrange
              isCustom
              disabled={currentIndex + 1 === fields?.cases?.length}
            >
              <button
                type="button"
                onClick={() => {
                  setCurrentIndex((prevState) => prevState + 1)
                }}
                disabled={currentIndex + 1 === fields?.cases?.length}
              >
                Volgende &nbsp;
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512.001 512.001"
                >
                  <path
                    d="M388.819,239.537L156.092,6.816c-9.087-9.089-23.824-9.089-32.912,0.002
	c-9.087,9.089-9.087,23.824,0.002,32.912l216.27,216.266L123.179,472.272c-9.087,9.089-9.087,23.824,0.002,32.912
	c4.543,4.544,10.499,6.816,16.455,6.816c5.956,0,11.913-2.271,16.457-6.817L388.819,272.45c4.366-4.364,6.817-10.283,6.817-16.455
	C395.636,249.822,393.185,243.902,388.819,239.537z"
                  />
                </svg>
              </button>
            </ButtonOrange>
          </SlideNavigation>
        </div>
      </div>
    </StyledClientCase>
  )
}

const StyledClientCaseBlockQuote = styled.svg`
  position: absolute;

  & path {
    fill: ${(props) => props.theme.color.face.light};
  }

  @media (min-width: 992px) {
    top: 35px;
    left: 45px;
    opacity: 0.2;
    height: 150px;
    width: 150px;
  }

  @media (max-width: 991px) {
    top: 25px;
    left: 25px;
    opacity: 0.15;
    height: 100px;
    width: 100px;
  }
`

interface ClientCaseBlockQuoteProps {
  className?: string
}

export const ClientCaseBlockQuote: React.FC<ClientCaseBlockQuoteProps> = ({
  className = '',
}) => (
  <StyledClientCaseBlockQuote
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="95.333px"
    height="95.332px"
    viewBox="0 0 95.333 95.332"
    className={className}
  >
    <g>
      <g>
        <path
          d="M30.512,43.939c-2.348-0.676-4.696-1.019-6.98-1.019c-3.527,0-6.47,0.806-8.752,1.793
			c2.2-8.054,7.485-21.951,18.013-23.516c0.975-0.145,1.774-0.85,2.04-1.799l2.301-8.23c0.194-0.696,0.079-1.441-0.318-2.045
			s-1.035-1.007-1.75-1.105c-0.777-0.106-1.569-0.16-2.354-0.16c-12.637,0-25.152,13.19-30.433,32.076
			c-3.1,11.08-4.009,27.738,3.627,38.223c4.273,5.867,10.507,9,18.529,9.313c0.033,0.001,0.065,0.002,0.098,0.002
			c9.898,0,18.675-6.666,21.345-16.209c1.595-5.705,0.874-11.688-2.032-16.851C40.971,49.307,36.236,45.586,30.512,43.939z"
        />
        <path
          d="M92.471,54.413c-2.875-5.106-7.61-8.827-13.334-10.474c-2.348-0.676-4.696-1.019-6.979-1.019
			c-3.527,0-6.471,0.806-8.753,1.793c2.2-8.054,7.485-21.951,18.014-23.516c0.975-0.145,1.773-0.85,2.04-1.799l2.301-8.23
			c0.194-0.696,0.079-1.441-0.318-2.045c-0.396-0.604-1.034-1.007-1.75-1.105c-0.776-0.106-1.568-0.16-2.354-0.16
			c-12.637,0-25.152,13.19-30.434,32.076c-3.099,11.08-4.008,27.738,3.629,38.225c4.272,5.866,10.507,9,18.528,9.312
			c0.033,0.001,0.065,0.002,0.099,0.002c9.897,0,18.675-6.666,21.345-16.209C96.098,65.559,95.376,59.575,92.471,54.413z"
        />
      </g>
    </g>
  </StyledClientCaseBlockQuote>
)

export default ClientCase
