import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import Share from 'components/elements/Share'
import ParseContent from 'components/shared/ParseContent'
import { ClientCaseBlockQuote } from 'components/flex/ClientCase'

// Context
import { VacancyContext } from 'templates/vacancy'

const MessageBlockSharing = styled.div`
  @media (max-width: 991px) {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
  }
`

const MessageSharingTitle = styled.div`
  font-size: ${(props) => props.theme.font.size.sm};
  color: ${(props) => props.theme.color.text.contrast};
`

const MessageBlock = styled.div`
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
`

const MessageIcon = styled(Image)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
  }
`

const MessageImage = styled(Image)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 576px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 575.98px) {
    max-width: 150px;
    margin: 0 auto;
  }
`

const MessageTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.l};
  color: ${({ theme }) => theme.color.text.purple};

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size[28]};
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.l};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.xm};
  }
`

const MessageQuote = styled(ClientCaseBlockQuote)`
  position: absolute;
  opacity: 1;
  z-index: 0;

  & path {
    fill: ${({ theme }) => theme.color.face.border};
  }

  @media (min-width: 576px) {
    width: 150px;
    height: 125px;
    left: -25px;
    top: -30px;
  }

  @media (max-width: 575px) {
    width: 100;
    height: 60px;
    left: -5px;
    top: -35px;
  }
`

const MessageDescription = styled(ParseContent)`
  color: ${(props) => props.theme.color.text.purple};
  position: relative;
  z-index: 1;

  & ul > li {
    margin-bottom: 0.25rem;
  }
`

interface VacancyDescriptionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_VacancyDescription
  location: any
}

const VacancyDescription: React.FC<VacancyDescriptionProps> = ({
  fields,
  location,
}) => {
  const vacancyContext = React.useContext(VacancyContext)

  return (
    <section>
      <div className="container py-4 py-lg-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-1 mb-4 mb-lg-0">
            <MessageBlockSharing className="pt-3 pb-3 pb-lg-0 pt-lg-5 d-lg-block d-flex align-items-center">
              <MessageSharingTitle className="text-center pr-lg-0 pr-4 pl-lg-0 pl-4">
                share
              </MessageSharingTitle>
              <div className="mt-3 d-lg-block d-none">
                <Share
                  location={location.href}
                  title={vacancyContext.title}
                  type="vertical"
                />
              </div>
              <div className="d-lg-none d-block">
                <Share
                  location={location.href}
                  title={vacancyContext.title}
                  type="horizontal"
                  className="d-flex"
                />
              </div>
            </MessageBlockSharing>
          </div>
          <div className="col-lg-9">
            <MessageBlock className="pt-lg-5 pt-4 px-3 px-sm-4 px-lg-5">
              {fields.descriptions?.map((edge, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="pb-4 pb-lg-5">
                  {edge?.typedescription === 'description' && (
                    <>
                      <div className="d-flex mb-4 align-items-center">
                        <MessageIcon
                          className="mr-4"
                          image={edge.icon}
                          alt={edge.title}
                        />
                        <MessageTitle>{edge?.title}</MessageTitle>
                      </div>
                      <MessageDescription content={edge?.description} />
                    </>
                  )}

                  {edge?.typedescription === 'quote' && (
                    <div className="row mb-sm-0 mb-4">
                      <div className="col-sm-5 d-flex align-items-center">
                        <MessageImage image={edge.image} />
                      </div>
                      <div className="col-sm-7 mt-4 mt-sm-0 position-relative d-flex align-items-center">
                        <MessageQuote />
                        <MessageDescription content={edge.description} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </MessageBlock>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VacancyDescription
