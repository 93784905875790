import React from 'react'

interface ShareButtonProps {
  className?: string
  location?: string
  title?: string
  children: any
}

export const ShareButtonFacebook: React.FC<ShareButtonProps> = ({
  className = '',
  location = '',
  children,
}) => (
  <ShareButtonAbstract
    className={className}
    href={`https://www.facebook.com/sharer/sharer.php?u=${location}`}
    platform="facebook"
  >
    {children}
  </ShareButtonAbstract>
)

export const ShareButtonTwitter: React.FC<ShareButtonProps> = ({
  className = '',
  title = '',
  location = '',
  children,
}) => (
  <ShareButtonAbstract
    className={className}
    href={`https://twitter.com/intent/tweet/?text=${title}&url=${location}`}
    platform="twitter"
  >
    {children}
  </ShareButtonAbstract>
)

export const ShareButtonLinkedIn: React.FC<ShareButtonProps> = ({
  className = '',
  title = '',
  location = '',
  children,
}) => (
  <ShareButtonAbstract
    className={className}
    href={`https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=${title}&summary=Mooi%20artikel!&amp;source=${location}`}
    platform="linkedin"
  >
    {children}
  </ShareButtonAbstract>
)

export const ShareButtonEmail: React.FC<ShareButtonProps> = ({
  className = '',
  title = '',
  location = '',
  children,
}) => (
  <a
    href={`mailto:%20?SUBJECT=${title}&amp;BODY=${location}`}
    className={className}
    data-platform="email"
  >
    {children}
  </a>
)

export const ShareButtonWhatsApp: React.FC<ShareButtonProps> = ({
  className = '',
  location = '',
  children,
}) => (
  <a
    href={`whatsapp://send?text=${location}`}
    data-action="share/whatsapp/share"
    className={className}
    data-platform="whatsapp"
  >
    {children}
  </a>
)

interface ShareButtonAbstractProps {
  className?: string
  platform: string
  href: string
  children: any
}

const ShareButtonAbstract: React.FC<ShareButtonAbstractProps> = ({
  className = '',
  platform,
  href,
  children,
}) => {
  const open = () => {
    window.open(
      href,
      'targetWindow',
      `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=500,
      height=500`
    )
  }

  return (
    <button
      type="button"
      onClick={open}
      className={className}
      data-platform={platform}
    >
      {children}
    </button>
  )
}
