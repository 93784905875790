import React from 'react'
import styled, { css } from 'styled-components'

// Socials
import {
  ShareButtonLinkedIn,
  ShareButtonFacebook,
  ShareButtonWhatsApp,
  ShareButtonEmail,
} from 'components/elements/ShareButton'

const ShareWrapper = styled.div<{ type: string }>`
  & button,
  & a {
    padding: 0;
    display: block;
    text-align: center;

    ${(props) =>
      props.type === 'vertical' &&
      css`
        margin: 0.5rem auto 0;
      `}

    ${(props) =>
      props.type === 'horizontal' &&
      css`
        margin: 0 0.5rem 0 0;
      `}
  }
`

const ShareButton = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  & svg {
    width: 15px;
    height: 15px;

    & path {
      fill: ${(props) => props.theme.color.text.light};
    }
  }
`

interface ShareProps {
  title?: string
  location: string
  className?: string
  type?: string
}

export const Share: React.FC<ShareProps> = ({
  title = '',
  className = '',
  location,
  type = 'vertical',
}) => (
  <ShareWrapper type={type} className={className}>
    <ShareButtonLinkedIn location={location} title={title}>
      <ShareButton>
        <svg
          height="682pt"
          viewBox="-21 -35 682.66669 682"
          width="682pt"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m77.613281-.667969c-46.929687 0-77.613281 30.816407-77.613281 71.320313 0 39.609375 29.769531 71.304687 75.8125 71.304687h.890625c47.847656 0 77.625-31.695312 77.625-71.304687-.894531-40.503906-29.777344-71.320313-76.714844-71.320313zm0 0" />
          <path d="m8.109375 198.3125h137.195313v412.757812h-137.195313zm0 0" />
          <path d="m482.054688 188.625c-74.011719 0-123.640626 69.546875-123.640626 69.546875v-59.859375h-137.199218v412.757812h137.191406v-230.5c0-12.339843.894531-24.660156 4.519531-33.484374 9.917969-24.640626 32.488281-50.167969 70.390625-50.167969 49.644532 0 69.5 37.851562 69.5 93.339843v220.8125h137.183594v-236.667968c0-126.78125-67.6875-185.777344-157.945312-185.777344zm0 0" />
        </svg>
      </ShareButton>
    </ShareButtonLinkedIn>
    <ShareButtonFacebook location={location}>
      <ShareButton>
        <svg
          id="Bold"
          enableBackground="new 0 0 24 24"
          height="512"
          viewBox="0 0 24 24"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" />
        </svg>
      </ShareButton>
    </ShareButtonFacebook>
    <ShareButtonWhatsApp location={location} title={title}>
      <ShareButton>
        <svg
          id="Bold"
          enableBackground="new 0 0 24 24"
          height="512"
          viewBox="0 0 24 24"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z" />
          <path d="m20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z" />
        </svg>
      </ShareButton>
    </ShareButtonWhatsApp>
    <ShareButtonEmail location={location} title={title}>
      <ShareButton>
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M467,61H45C20.218,61,0,81.196,0,106v300c0,24.72,20.128,45,45,45h422c24.72,0,45-20.128,45-45V106
			C512,81.28,491.872,61,467,61z M460.786,91L256.954,294.833L51.359,91H460.786z M30,399.788V112.069l144.479,143.24L30,399.788z
			 M51.213,421l144.57-144.57l50.657,50.222c5.864,5.814,15.327,5.795,21.167-0.046L317,277.213L460.787,421H51.213z M482,399.787
			L338.213,256L482,112.212V399.787z"
              />
            </g>
          </g>
        </svg>
      </ShareButton>
    </ShareButtonEmail>
  </ShareWrapper>
)

export default Share
